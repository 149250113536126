export default {

    lobbySetting(state) {
        return state.lobbySetting;
    },
    recentlyGames(state) {
        return state.recentlyGames;
    },
    games(state) {
        return state.games;
    },
    gameTypes(state) {
        return state.gameTypes;
    },
    balance(state) {
        return state.balance;
    }
};
